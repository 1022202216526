
import { defineComponent } from "vue";
import { ElMessage, ElMessageBox, ElLoading } from "element-plus";
import Chart from "chart.js/auto";
import _ from "lodash";
import helpers from "@/helpers/global";

let loadingEl: any;

export default defineComponent({
  name: "MainCardReults",
  props: ["disabledRating", "locale", "isRespondent"],
  watch: {
    error(value) {
      console.error(value);
    },
    locale(value) {
      const setLocale = (lang: string) => {
        (this as any).$i18n.setLocale(lang);
      };
      setLocale(value);
      this.$store.commit("assessment/locale", value, { root: true });
    },
    loading(value) {
      if (value) {
        loadingEl = ElLoading.service({
          lock: true,
          text: "Sending...",
          background: "#ffffff90",
        });
      } else {
        loadingEl.close();
      }
    },
    data(value) {
      if (value) {
        // this component used by candidate as a result not company view
        if (this.isRespondent) {
          if (value && value.lang) {
            let locale = String(value.lang) || "en";
            const lang = this.$router.currentRoute.value.meta.lang;
            if (lang && lang == "TH") {
              locale = "th";
            } else if (lang && lang == "EN") {
              locale = "en";
            }
            (this as any).$i18n.setLocale(locale);
            this.$store.commit("assessment/locale", locale, { root: true });
          }
        }
      }
    },
  },
  data() {
    return {
      assessmentsName: "Culture Fit for Jan 2022 Batch",
      culturePreferenceFocus: {
        value: 82,
        title: "Overall",
        title_th: "ภาพรวม",
        id: "Overall",
        status: "green",
        description: "Select a dimension to learn more",
        candidate: {
          clan: 0,
          create: 0,
          complete: 0,
          control: 0,
        },
        company: {
          clan: 0,
          create: 0,
          complete: 0,
          control: 0,
        },
      },
      focusUser: {
        id: "no_user",
        name: "-",
        firstname: "-",
        lastname: "-",
        companyName: "-",
        email: "=",
        status: "Completed",
        joined: "=",
        completed: "=",
        score: "-",
        yourRating: 0,
        valuesAndTraits: {
          overall: "72%",
          values: "88%",
          valueTags: [
            {
              label: "Integrity",
              id: "Integrity",
              status: "green",
            },
            {
              label: "Entrepreneurship",
              id: "Entrepreneurship",
              status: "green",
            },
            {
              label: "Respect",
              id: "Respect",
              status: "green",
            },
            {
              label: "Honesty",
              id: "Honesty",
              status: "yellow",
            },
            {
              label: "Excellence",
              id: "Excellence",
              status: "yellow",
            },
            {
              label: "Growth",
              id: "Growth",
              status: "yellow",
            },
            {
              label: "Winning",
              id: "Winning",
              status: "red",
            },
            {
              label: "Collaboration",
              id: "Collaboration",
              status: "red",
            },
          ],
          traits: "72%",
          traitTags: [
            {
              label: "Integrity",
              id: "Integrity",
              status: "green",
            },
            {
              label: "Entrepreneurship",
              id: "Entrepreneurship",
              status: "green",
            },
            {
              label: "Respect",
              id: "Respect",
              status: "green",
            },
            {
              label: "Honesty",
              id: "Honesty",
              status: "green",
            },
            {
              label: "Excellence",
              id: "Excellence",
              status: "yellow",
            },
            {
              label: "Growth",
              id: "Growth",
              status: "yellow",
            },
            {
              label: "Winning",
              id: "Winning",
              status: "yellow",
            },
            {
              label: "Collaboration",
              id: "Collaboration",
              status: "red",
            },
          ],
        },
        culturePreference: {
          overall: "76%",
          overallStatus: "green",
          data: [
            {
              value: "82%",
              title: "Overall",
              id: "Overall",
              status: "green",
              description:
                "The overall overlap between the candidate’s ideal work culture and your organization’s work culture.",
              user: {
                clan: 11,
                create: 12,
                complete: 14,
                control: 12,
              },
              company: {
                clan: 11,
                create: 12,
                complete: 14,
                control: 12,
              },
            },
            {
              value: "82%",
              title: "Dominant Characteristics",
              id: "Dominant Characteristics",
              status: "green",
              description:
                "The overall overlap between the candidate’s ideal work culture and your organization’s work culture.",
              user: {
                clan: 11,
                create: 12,
                complete: 14,
                control: 12,
              },
              company: {
                clan: 11,
                create: 12,
                complete: 14,
                control: 12,
              },
            },
            {
              value: "81%",
              title: "Organizational Leadership",
              id: "Organizational Leadership",
              status: "green",
              description:
                "The overall overlap between the candidate’s ideal work culture and your organization’s work culture.",
              user: {
                clan: 11,
                create: 12,
                complete: 14,
                control: 12,
              },
              company: {
                clan: 11,
                create: 12,
                complete: 14,
                control: 12,
              },
            },
            {
              value: "79%",
              title: "Management of Employees",
              id: "Management of Employees",
              status: "yellow",
              description:
                "The overall overlap between the candidate’s ideal work culture and your organization’s work culture.",
              user: {
                clan: 11,
                create: 12,
                complete: 14,
                control: 12,
              },
              company: {
                clan: 11,
                create: 12,
                complete: 14,
                control: 12,
              },
            },
            {
              value: "75%",
              title: "Organization Glue",
              id: "Organization Glue",
              status: "yellow",
              description:
                "The overall overlap between the candidate’s ideal work culture and your organization’s work culture.",
              user: {
                clan: 11,
                create: 12,
                complete: 14,
                control: 12,
              },
              company: {
                clan: 11,
                create: 12,
                complete: 14,
                control: 12,
              },
            },
            {
              value: "70%",
              title: "Strategic Emphases",
              id: "Strategic Emphases",
              status: "yellow",
              description:
                "The overall overlap between the candidate’s ideal work culture and your organization’s work culture.",
              user: {
                clan: 11,
                create: 12,
                complete: 14,
                control: 12,
              },
              company: {
                clan: 11,
                create: 12,
                complete: 14,
                control: 12,
              },
            },
            {
              value: "59%",
              title: "Criteria of Success",
              id: "Criteria of Success",
              status: "red",
              description:
                "The overall overlap between the candidate’s ideal work culture and your organization’s work culture.",
              user: {
                clan: 11,
                create: 12,
                complete: 14,
                control: 12,
              },
              company: {
                clan: 11,
                create: 12,
                complete: 14,
                control: 12,
              },
            },
          ],
        },
        acceptableBehaviors: {
          overall: "76%",
          scenarioData: [
            {
              id: "soelja-5ae58x-a41ed",
              title: "Scenario 1",
              description: "Regularly showing up 5-min late to work",
              matchingStatus: true,
              companyValueLabel: "Frowned Upon",
              companyStatus: "green",
              userValueLabel: "Frowned Upon",
              userStatus: "green",
            },
            {
              id: "soelja-5a2e58x-a41e2d",
              title: "Scenario 2",
              description:
                "Unresponsive over chat for most of the day without an away status.",
              matchingStatus: false,
              companyValueLabel: "Frowned Upon",
              companyStatus: "green",
              userValueLabel: "Acceptable",
              userStatus: "red",
            },
            {
              id: "soelja-5ae58x-a41ed",
              title: "Scenario 1",
              description:
                "Regularly showing up 5-min late to work, It is description too longgggggggggggggggg ggggggggg woard on here and show what is going on, testset settset",
              matchingStatus: true,
              companyValueLabel: "Frowned test",
              companyStatus: "yellow",
              userValueLabel: "Frowned test",
              userStatus: "yellow",
            },
          ],
        },
      },
      culturePreferenceChartTag: "",
      radarChartVal: {},
      culturePreferenceRenderChart: false,
      doughnutChartVal: {},
      tooltipOncultureStatus: true,
      tooltipOncultureLabelPrefix: "",
      tooltipOncultureLabel: {
        collaborate: "",
        create: "",
        compete: "",
        control: "",
      },
      id: "",
      skills: [
        // {
        //   label: "Communication",
        //   value: 80,
        //   content:
        //     "The candidate shows strong situational awareness by identifying the issues at hand, understanding the impact on the project and stakeholders, and taking appropriate steps to address them. They consider the perspectives of various stakeholders and adapt their approach to ensure the project stays on track.",
        // },
        // {
        //   label: "Courage to fail",
        //   value: 37,
        //   content:
        //     "The candidate shows strong situational awareness by identifying the issues at hand, understanding the impact on the project and stakeholders, and taking appropriate steps to address them. They consider the perspectives of various stakeholders and adapt their approach to ensure the project stays on track.",
        // },
        // {
        //   label: "Communication",
        //   value: 80,
        //   content:
        //     "The candidate shows strong situational awareness by identifying the issues at hand, understanding the impact on the project and stakeholders, and taking appropriate steps to address them. They consider the perspectives of various stakeholders and adapt their approach to ensure the project stays on track.",
        // },
        // {
        //   label: "Courage to fail",
        //   value: 37,
        //   content:
        //     "The candidate shows strong situational awareness by identifying the issues at hand, understanding the impact on the project and stakeholders, and taking appropriate steps to address them. They consider the perspectives of various stakeholders and adapt their approach to ensure the project stays on track.",
        // },
      ] as any,
    };
  },
  computed: {
    error() {
      return this.$store.getters["assessment/error"];
    },
    loading() {
      return this.$store.getters["assessment/loading"];
    },
    data() {
      return this.$store.getters["assessment/data"] || {};
    },
    unselectedQuestions() {
      return this.$store.getters["assessment/unselectedQuestions"] || {};
    },
    unselectedValueQuestions() {
      const unselectedQuestions = this.unselectedQuestions;
      return unselectedQuestions.defining_your_values;
    },
    unselectedTraitQuestions() {
      const unselectedQuestions = this.unselectedQuestions;
      return unselectedQuestions.identifying_key_traits;
    },
    formTemplate() {
      return this.$store.getters["assessment/formTemplate"] || {};
    },
    hasAcceptableBehaviors() {
      const data = this.data;
      const hasAcceptableBehaviors = data.acceptable_behaviors || false;
      return hasAcceptableBehaviors;
    },
    summaries() {
      const summaries = this.$store.getters["assessment/summaries"] || {};

      // this view want to sort values and traits by score
      const hasOwnProperty = Object.prototype.hasOwnProperty;
      if (hasOwnProperty.call(summaries, "values_and_traits")) {
        for (const i in summaries.values_and_traits.scenarios) {
          const scenario = summaries.values_and_traits.scenarios[i];
          const questions = scenario.questions;
          const name = _.snakeCase(scenario.name);
          const values = summaries.values_and_traits.candidate[name];
          questions.sort((a: any, b: any) => {
            const scoreA = values[a.id];
            const scoreB = values[b.id];
            if (scoreA == scoreB) {
              if (a && b) {
                const titleA = a.name;
                const titleB = b.name;
                return titleA > titleB;
              }
            }
            return scoreB - scoreA;
          });
        }
      }
      return summaries;
    },
    companyColor() {
      const defaultCompanyColor = "#000";
      const user = this.$store.getters["user/user"];
      if (user && user.color) {
        return user.color;
      }
      return defaultCompanyColor;
    },
    workStyleFocusedType() {
      let result = "";
      const data = this.data || {};
      if (data && data.summaries && data.summaries.work_style_identifier) {
        const overall = data.summaries.work_style_identifier.overall;
        let objects = _.sortBy(
          [
            {
              name: "People",
              value: overall.people,
            },
            {
              name: "Process",
              value: overall.process,
            },
            {
              name: "Product",
              value: overall.product,
            },
          ],
          "value"
        );
        const max = objects[objects.length - 1].value;
        objects = _.filter(objects, { value: max });
        for (let i = 0; i < objects.length; i++) {
          let sep = i > 0 ? ", " : "";
          if (objects.length > 1 && i == objects.length - 1) sep = " and ";
          result += sep + objects[i].name + "-focuses";
        }
      }
      return result;
    },
  },
  async mounted() {
    const valid = this.checkPropsId();
    if (valid) {
      await this.initialize();
      this.renderWorkStyleDoughnutChart();
    }
    // this.$store.dispatch("forceReady", null, { root: true });
  },
  methods: {
    async initialize() {
      const u_id = String(this.$router.currentRoute.value.query.u_id);
      const _id = decodeURIComponent(atob(u_id));
      this.id = _id;

      // @note DO NOT compute score all time
      this.$store.commit("assessment/ignoreLoading", true);
      await this.$store.dispatch("assessment/getSummaries", _id, {
        root: true,
      });
      this.$store.commit("assessment/ignoreLoading", false);

      // for graph rendering
      this.focusUser.firstname = this.data.firstname;
      this.focusUser.companyName = this.data.company_name;

      if (this.data.summaries && this.data.summaries.oaic_archetypes) {
        for (const i in this.data.summaries.oaic_archetypes.candidate_avg) {
          this.data.summaries.oaic_archetypes.candidate_avg[i] = Math.round(
            this.data.summaries.oaic_archetypes.candidate_avg[i]
          );
          this.data.summaries.oaic_archetypes.company_avg[i] = Math.round(
            this.data.summaries.oaic_archetypes.company_avg[i]
          );
        }
      }

      const humanSkills = _.get(this.data, "summaries.human_skills");
      if (humanSkills) {
        // Order is important!
        // Left: Written comunication, Empathy, Situmation awareness, Critical Thinking
        // Right: Courage to fail, Initiative making, Responsiveness, Excellent

        this.skills = [];
        const leftColumns = [];
        const rightColumns = [];

        // Left Columns
        for (const i in humanSkills.human_skills) {
          const humanSkill = humanSkills.human_skills[i];
          leftColumns.push({
            label: i.split(/[_-]/g).map(helpers.ucfirst).join(" "),
            content: humanSkill.full_explanation,
            value: humanSkill.score,
          });
        }

        leftColumns.sort((a: { label: string }, b: { label: string }) => {
          const indexMap = {
            "Written Communication": 1,
            Empathy: 2,
            "Situational Awareness": 3,
            "Critical Thinking": 4,
          };
          const aIndex = a.label || "";
          const bIndex = b.label || "";
          const $a = _.get(indexMap, aIndex);
          const $b = _.get(indexMap, bIndex);
          return $a - $b;
        });

        // Right Columns
        for (const i in humanSkills.core_values) {
          const coreValue = humanSkills.core_values[i];
          rightColumns.push({
            label: i.split(/[_-]/g).map(helpers.ucfirst).join(" "),
            content: coreValue.full_explanation,
            value: coreValue.score,
          });
        }

        const maxRows = Math.max(leftColumns.length, rightColumns.length);
        const bogusColumn = {
          label: "",
          content: "",
          value: "",
          visibility: false,
        };
        for (let i = 0; i < maxRows; i++) {
          if (leftColumns[i]) {
            this.skills.push(leftColumns[i]);
          } else {
            this.skills.push(bogusColumn);
          }
          if (rightColumns[i]) {
            this.skills.push(rightColumns[i]);
          } else {
            this.skills.push(bogusColumn);
          }
        }
      }

      await this.$store.dispatch(
        "assessment/observeSummaries",
        { _id: _id },
        {
          root: true,
        }
      );
    },
    dateFormat(datetime: string) {
      return helpers.dateFormat(datetime);
    },
    donwloadPDF(value: any) {
      console.log("donwloadPDF", value);
    },
    checkPropsId() {
      let valid = true;
      const as_id = this.$router.currentRoute.value.query.as_id;
      const u_id = this.$router.currentRoute.value.query.u_id;
      if (as_id) {
        if (u_id) {
          this.focusUser.id = u_id + "";
        }
      } else {
        valid = false;
      }
      return valid;
    },
    renderChartculturePreference() {
      if (!this.culturePreferenceRenderChart) {
        this.chartculturePreference();
      } else {
        setTimeout(() => {
          this.renderChartculturePreference();
        }, 300);
      }
    },
    chartculturePreference() {
      this.culturePreferenceRenderChart = true;
      const chartContent = this.$refs.culture_preference_chart;

      if (this.radarChartVal && Object.keys(this.radarChartVal).length) {
        (this.radarChartVal as any).destroy();
      }

      if (chartContent) {
        const ctx = (chartContent as any).getContext("2d");
        const options = {
          elements: {
            line: {
              backgroundColor: "rgba(0, 0, 0, 0)",
              borderWidth: 2,
            },
          },
          scales: {
            r: {
              grid: {
                display: false,
              },
              min: 0,
              ticks: {
                display: false,
                color: "rgba(0, 0, 0, 0)",
                beginAtZero: false,
                stepSize: 10,
                callback: function () {
                  return "";
                },
              },
              pointLabels: {
                color: "#000",
                fontSize: 14,
                font: {
                  size: 14,
                  weight: "700",
                },
              },
              gridLines: {
                lineWidth: 2,
                color: "lightgreen",
                borderDash: (context: any) =>
                  context.index == 6 ? [] : [6, 4],
              },
            },
          },
          plugins: {
            title: {
              display: false,
            },
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
          },
        };
        const user = [
          this.culturePreferenceFocus.candidate.clan,
          this.culturePreferenceFocus.candidate.create,
          this.culturePreferenceFocus.candidate.complete,
          this.culturePreferenceFocus.candidate.control,
        ];
        const company = [
          this.culturePreferenceFocus.company.clan,
          this.culturePreferenceFocus.company.create,
          this.culturePreferenceFocus.company.complete,
          this.culturePreferenceFocus.company.control,
        ];

        const daatsets = [];
        daatsets.push({
          label: this.focusUser.companyName,
          data: company,
          pointStyle: "line",
          borderColor: this.companyColor,
          borderDash: [3, 1],
        });

        daatsets.push({
          label: this.focusUser.firstname,
          data: user,
          pointStyle: "line",
          borderColor: "#30CCB4",
        });

        this.radarChartVal = new Chart(ctx, {
          type: "radar",
          data: {
            labels: [
              " ",
              " ",
              " ",
              " ",
              // "Collaborate people-oriented",
              // "Create dynamic & entrepreneurial",
              // "Complete result-oriented",
              // "Control process-oriented",
            ],
            // datasets: daatsets,
            datasets: daatsets,
          },
          options: options,
        });
      }
      setTimeout(() => {
        this.culturePreferenceRenderChart = false;
      }, 1000);
    },
    drilldownOnCulture(data: any) {
      if (data.id == "overall") {
        this.tooltipOncultureStatus = true;
      } else {
        this.tooltipOncultureStatus = false;
        this.tooltipOncultureLabelPrefix =
          (this.lang(data, "admin_description") || "") + " ";
        this.tooltipOncultureLabel.collaborate =
          data.tooltipOncultureLabel.collaborate || "";
        this.tooltipOncultureLabel.create =
          data.tooltipOncultureLabel.create || "";
        this.tooltipOncultureLabel.compete =
          data.tooltipOncultureLabel.compete || "";
        this.tooltipOncultureLabel.control =
          data.tooltipOncultureLabel.control || "";
      }
      const dataValue = _.cloneDeep(data);
      dataValue.value = Math.round(dataValue.value);
      for (const i in dataValue.company) {
        dataValue.company[i] = Math.round(dataValue.company[i]);
        dataValue.candidate[i] = Math.round(dataValue.candidate[i]);
      }
      this.culturePreferenceFocus = dataValue;
      this.renderChartculturePreference();
    },
    renderWorkStyleDoughnutChart() {
      let chartData = [0, 0, 0];
      if (this.data.summaries && this.data.summaries.work_style_identifier) {
        const summary = this.data.summaries.work_style_identifier;
        chartData = [
          Math.round(summary.overall.product * 100),
          Math.round(summary.overall.process * 100),
          Math.round(summary.overall.people * 100),
        ];
      }

      const getChart = this.$refs.work_style_chart;
      if (getChart && this.culturePreferenceFocus) {
        try {
          const data = {
            labels: ["Red", "Blue", "Yellow"],
            datasets: [
              {
                label: "My First Dataset",
                // data: [60, 30, 10],
                data: chartData,
                backgroundColor: ["#FAB340", "#CC3066", "#309AE8"],
                hoverOffset: 4,
              },
            ],
          };
          const ctx = (getChart as any).getContext("2d");
          const options = {
            cutout: "60%",
            plugins: {
              title: {
                display: false,
              },
              legend: {
                display: false,
              },
              tooltip: {
                enabled: false,
              },
            },
          };

          this.doughnutChartVal = new Chart(ctx, {
            type: "doughnut",
            data: data,
            options: options,
          });
        } catch (e) {
          console.log(e, "error");
          return;
        }
      }
    },
    score(value: any) {
      return Math.round(value);
    },
    scoreCls(value: any) {
      const score = this.score(value);
      let cls = "red";
      if (score >= 80) {
        cls = "green";
      } else if (score >= 60) {
        cls = "yellow";
      }
      return cls;
    },
    sendEmailResults(data: any) {
      ElMessageBox.confirm(
        (this as any).$t("popup.question.question_box_1.question_1") +
          data.firstname +
          (this as any).$t("popup.question.question_box_1.question_2"),
        (this as any).$t("popup.question.question_box_1.title") +
          data.firstname,
        {
          confirmButtonText: (this as any).$t("popup.optional.Yes"),
          cancelButtonText: (this as any).$t("popup.optional.Cancel"),
          type: "warning",
        }
      ).then(() => {
        this.$store.dispatch("assessment/sentResultToCandidateEmail", data, {
          root: true,
        });
        ElMessage({
          type: "success",
          message: (this as any).$t("popup.message.message_1"),
        });
      });
    },
    coppyLink() {
      // @see cloud function
      const _id = this.id;
      const actionUrl = this.data.action_url;
      let linkUrl = "";
      if (actionUrl) {
        const url = new URL(actionUrl);
        const asId = _id.split("-")[0];
        const uId = btoa(encodeURIComponent(_id));
        linkUrl =
          url.origin +
          "/assessment-results" +
          "?as_id=" +
          asId +
          "&u_id=" +
          uId;
      }

      const value = linkUrl;
      const clipboardData =
        (window as any).clipboardData || navigator.clipboard;
      clipboardData.writeText(value);
      ElMessage({
        message: (this as any).$t("popup.message.message_19"),
        type: "success",
      });
    },
    // @todo I'll refactor this after launch beta version (common function)
    lang(obj: any, attr: string) {
      // @note hold all this issue waiting all translation
      const lang = (this as any).$i18n.getLocale(0) || "en";
      let label = "";
      if (_.isObject(obj)) {
        if (lang != "en") {
          attr = attr + "_" + lang;
        }
        if (Object.prototype.hasOwnProperty.call(obj, attr)) {
          label = (obj as any)[attr];
        }
      }
      return label;
    },
    changeAcceptableScore(value: any) {
      const command = value.command || "";
      const data = value.data || {};

      const valueMap = [
        "Acceptable",
        "Frowned Upon",
        "Actively Discouraged",
        "Reprimanded",
        "Zero Tolerance Policy",
      ];
      const candidateValue = _.indexOf(valueMap, command);
      value.data.candidateValue = candidateValue;

      const changedData: any = {};
      changedData[data.scenarioName] = {};
      changedData[data.scenarioName][data.questionId] = candidateValue;

      const formDetails = {
        _id: this.id,
        acceptable_behaviors: changedData,
      };
      this.$store.dispatch("assessment/updateAcceptableBehavior", formDetails, {
        root: true,
      });
    },
    overAllPercentageColorCode(value: number) {
      // #color_code
      if (value > 79) {
        return "#28BE79";
      } else if (value > 59) {
        return "#FBC02D";
      } else {
        return "#CC308A";
      }
    },
    checkingNaN(value: number) {
      return isNaN(value) ? "-" : Math.round(value);
    },
    checkOpacityAndColor(value: number, maxBar: number) {
      let opacity = 1;
      if (value > maxBar) {
        opacity = 1;
      } else if (value + 20 > maxBar) {
        opacity = ((value + 20 - maxBar) / 2) * 0.1;
      } else {
        opacity = 0.1;
      }
      let color = "#CC308A";
      if (value > 79) {
        color = "#28BE79";
      } else if (value > 59) {
        color = "#FBC02D";
      } else {
        color = "#CC308A";
      }

      return `opacity: ${opacity}; background-color: ${color}`;
    },
    showHumanTime(value: number): string {
      if (!value) return `-`;
      let minutes: any = Math.floor(value / 60);
      minutes = minutes >= 10 ? String(minutes) : "0" + String(minutes);
      let seconds: any = value % 60;
      seconds = seconds >= 10 ? String(seconds) : "0" + String(seconds);
      return `${minutes}:${seconds}`;
    },
  },
});
